(function(){
    "use strict";

    var noop = function(){};
    var __gaTracker = __gaTracker;

    if (typeof __gaTracker === 'undefined'){
        __gaTracker = noop;
    }

    var attachFastClick = require('fastclick');
    attachFastClick(document.body);

    var $ = require('jquery');
    var Flickity = require('flickity');
    var NProgress = require('nprogress');
    var pagejs = require('page');
    var url = require('wurl');

    var lastPage = document.location.pathname;
    var page = function(url, callback){

        if (typeof url === 'object' ){
            pagejs(url);
        } else if (typeof callback !== 'undefined') {
            pagejs(url, callback);
        } else {
            if ( history.state ) {
                lastPage = history.state.path;
            }
            pagejs(url);
        }
    };

    window.onpopstate = function(event) {
        console.log(event);
    };

    $(document).ajaxError(function(){
        NProgress.done();
    });

    var showVideo = function(){
        $('.viewport').stop().fadeOut(300);

        $('.video-player').animate({opacity: 1}, 300);

    };

    var hideVideo = function() {
        var iframe = $('.video-player iframe');

        $('.video-player').animate({opacity: 0}, 300);
        $('.viewport').stop().fadeIn(300, function () {
            iframe.remove();
        });

    };

    var showContent = function(){
        hideVideo();
        $('.page-content').addClass('is-shown');
        $('.content-blocker').addClass('is-shown');
    };


    var hideContent = function(){
        $('.page-content').removeClass('is-shown');
        $('.content-blocker').removeClass('is-shown');
    };

    var index = function(){
        __gaTracker('set', 'page', '/');
        document.title = 'Homepage - Paul Caslin';
        hideContent();
        hideVideo();
        __gaTracker('send', 'pageview');
    };

    page('/', index);

    page('', index);

    page('/about', function(ctx){
        __gaTracker('set', 'page', '/about');
        $('.content-holder').load('/?page_id=11 .article', function(response){
            __gaTracker('send', 'pageview');
            document.title = $(response).filter('title').text();
            NProgress.done();
            showContent();
        });
    });

    page('/work', function(ctx){
        __gaTracker('set', 'page', '/work');
        $('.content-holder').load('/?page_id=15 .article', function(response){
            __gaTracker('send', 'pageview');
            document.title = $(response).filter('title').text();
            NProgress.done();
            showContent();
        });
    });

    page('/contact', function(ctx){
        __gaTracker('set', 'page', '/contact');
        $('.content-holder').load('/?page_id=17 .article', function(response){
            __gaTracker('send', 'pageview');
            document.title = $(response).filter('title').text();
            NProgress.done();
            showContent();
        });
    });

    page('/video/:title', function(ctx){
        __gaTracker('set', 'page', '/video/'+ctx.params.title);

        $.get('/?page_id=15', function(data){
            __gaTracker('send', 'pageview');
            NProgress.done();
            var page = $(data);
            var video = page.find('[href="'+location.protocol+'//'+location.host+'/video/'+ctx.params.title+'/"]').parent().data();

            var vidId = video.videoUrl.match(/(\d+)/g);

            document.title = video.videoTitle + " - Paul Caslin";

            $('<iframe/>', {
                'src': 'https://player.vimeo.com/video/'+vidId+'?autoplay=1',
                'frameborder': '0',
                'webkitallowfullscreen': true,
                'mozallowfullscreen': true,
                'allowfullscreen': true
            }).appendTo('.video__embed');

            showVideo();
        });
    });

    page('/:pageTitle', function(ctx){
        __gaTracker('set', 'page', '/'+ctx.params.pageTitle);

        $('.content-holder').load('/'+ctx.params.pageTitle+' .article', function(response){
            __gaTracker('send', 'pageview');
            document.title = $(response).filter('title').text();
            NProgress.done();

            showContent();
        });

    });

    page({ click: false, dispatch: true, popstate: true });
    __gaTracker('send', 'pageview');


    new Flickity( '.main-gallery', {
        cellAlign: 'left',
        contain: true,
        lazyLoad: 1,
        autoPlay: 5000,
        watchCSS: true,
        prevNextButtons: true,
        pageDots: false,
        setGallerySize: false,
        wrapAround: true
    });

    $('.gallery-cell').on('click', '.play-button', function(e){
        e.preventDefault();
        NProgress.start();
        page($(this).attr('href').replace(location.protocol+'//'+location.host, ''));
    });

    $('.video__close').on('click', function(e){
        e.preventDefault();

        hideVideo();

        if ( lastPage.match(/video/g) === null ) {
            page(lastPage);
        } else {
            page('/');
        }

    });

    $('.mobile-navigation-trigger').on('click', function(){
       $(this).toggleClass('is-active');

        $('.nav-primary').fadeToggle();
    });


    $('.menu-item a').on('click', function(e){
        e.preventDefault();

        var path = null;

        path = $(this).attr('href').replace(location.protocol+'//'+location.host, '');

        NProgress.start();
        page(path);

    });

    $(document).on('click', '.video-thumb', function(e){
        e.preventDefault();
        NProgress.start();

        page($(this).attr('href').replace(location.protocol+'//'+location.host, ''));
    });


    $('.page-content').on('click', '.close', function(e){
        e.preventDefault();
        page('/');
    });

    $('.legal').on('click', 'a', function(e){
        e.preventDefault();
        NProgress.start();

        var pageTitle = $.trim($(this).text()).replace(' ', '-').toLowerCase();
        var pageId = $(this).data('page-id');

        page('/'+pageTitle);
    });

})();
